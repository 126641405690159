<template>
  <div>
    <div class="justify-content-between py-3 d-flex d-lg-none">
      <div class="">{{ paginator.count }} {{ trans('general.results') | capitalize }}</div>
    </div>
    <search-cards
      v-bind="$attrs"
      source-property="results"
    />
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import SearchCards from '../SearchCards.vue';

export default {
  name: 'CenterSearchCards',
  components: { SearchCards },
  computed: {
    ...mapFields('map', ['paginator']),
  },
};
</script>
