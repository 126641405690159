import Vue from 'vue';
import CapitalizeFilter from '../../helpers/filters/CapitalizeFilter';
import '../../helpers/sentry';

import 'bootstrap';

// Mixins
import '../../components/mixins/Translator';
import '../../components/mixins/Capitalize';

import './components.registration.map';

import './_gmap';
import './_select2';

// Filters
// Vue.filter('formatDate', FormatDateFilter);
Vue.filter('capitalize', CapitalizeFilter);
