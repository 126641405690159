<template>
  <div class="container-fluid pt-4">
    <div class="row">
      <div class="col-12 col-lg-4 col-xl-3 col-xxl-2">
        <div class="mapsearchsticky">
          <map-search-filters
            :country-code="countryCode"
            :is-map-shown.sync="isMapShown"
            @update="handleUpdate"
          />

          <map-ads class="d-none d-lg-block" />
        </div>
      </div>

      <div class="col-12 col-lg-8 col-xl-9 col-xxl-10 px-0">
        <div class="justify-content-between px-3 d-none d-lg-flex">
          <div v-if="!isLoading">
            {{ paginator.count }} {{ trans('general.results') | capitalize }}
          </div>
          <div class="d-lg-block d-none">
            <b-form-checkbox
              :checked="isMapShown"
              switch
              @change="isMapShown = !isMapShown"
            >
              {{ trans('map.displayMap') | capitalize }}
            </b-form-checkbox>
          </div>
        </div>
        <div id="map-search-result-container">
          <map-search-result-lookup-here
            v-if="isMapShown"
            @click="handleLookupHere"
          />

          <map-search-result-map
            v-if="isMapDisplayed"
            id="map-search-result-map"
            ref="map"
            :class="{ 'd-none': !isMapShown }"
            :selected-center="details?.id"
            @details="handleDetails"
            @update.once="loadCentersForMap"
          >
            <map-search-result-map-detail
              v-if="details"
              :details="details"
              @close="details = null"
            />
          </map-search-result-map>
          <map-search-cards class="px-3" />

          <map-ads class="d-block d-lg-none" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { getGoogleMapsAPI } from 'gmap-vue';
import MapSearchFilters from './center/SearchFilters.vue';
import MapSearchCards from './center/SearchCards.vue';
import MapAds from './MapAds.vue';
import MapSearchResultMap from './MapSearchResultMap.vue';
import MapSearchResultMapDetail from './MapSearchResultMapDetail.vue';
import MapSearchResultLookupHere from './MapSearchResultLookupHere.vue';
import { loadCenter } from '../../services/api/Center';

export default {
  name: 'MapApp',
  components: {
    MapAds,
    MapSearchCards,
    MapSearchFilters,
    MapSearchResultLookupHere,
    MapSearchResultMap,
    MapSearchResultMapDetail,
  },
  props: { countryCode: { type: String, required: true } },
  data() {
    return {
      isMapShown: false,
      isMapDisplayed: false,
      details: null,
      center: null,
    };
  },
  computed: {
    ...mapFields('map', [
      'displayMode',
      'geocoding',
      'isLoading',
      'paginator',
      'sport',
      'when',
      'zoneName',
    ]),
    google: getGoogleMapsAPI,
  },
  watch: {
    isMapShown(n) {
      if (n && !this.isMapDisplayed) {
        this.isMapDisplayed = true;
      }

      if (!n) {
        this.details = null;
      }
    },
  },
  async created() {
    this.parseQuery();
    await this.loadCenters();
  },
  methods: {
    parseQuery() {
      const q = new URLSearchParams(window.location.search);
      let parsedQuery = null;

      if (q.has('q')) {
        try {
          parsedQuery = JSON.parse(decodeURIComponent(q.get('q')));
          // console.dir(parsedQuery);
          if (
            Object.hasOwnProperty.call(parsedQuery, 'what') &&
            Number.isInteger(parsedQuery.what)
          ) {
            this.sport = parsedQuery.what;
          }
          if (Object.hasOwnProperty.call(parsedQuery, 'where') && parsedQuery.where?.lng) {
            const { lat, lng } = parsedQuery.where;
            this.geocoding = { lat, lng };
            this.zoneName = parsedQuery.where.name;
          }
          if (Object.hasOwnProperty.call(parsedQuery, 'when') && parsedQuery.when) {
            this.when = parsedQuery.when;
          }
        } catch (e) {
          console.error(e);
        }
      }

      // console.dir(parsedQuery);
    },
    async handleUpdate() {
      await this.loadCenters();
    },
    async handleLookupHere() {
      const c = this.$refs.map.getCenter();

      const lat = typeof c.lat === 'number' ? c.lat : c.lat();
      const lng = typeof c.lng === 'number' ? c.lng : c.lng();

      this.geocoding = {
        lat,
        lng,
      };

      await this.loadCentersForMap();
      await this.loadCenters();
    },
    async loadCentersForMap() {
      await this.$store.dispatch('map/loadCentersForMap');
    },
    async loadCenters() {
      await this.$store.dispatch('map/loadCenters');
    },
    async handleDetails(id) {
      const response = await loadCenter(id);
      this.details = response;
    },
  },
};
</script>
